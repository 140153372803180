import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { SendOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAccount, useMsal } from '@azure/msal-react';
import { Dayjs } from "dayjs";

import { CustomDatePicker } from '../../components/DatePicker';
import { SelectRole, SelectTenant } from '../../components/Select';
import { AccessRequestForm, IdNameDto, RoleDto } from "../../models";
import { fetchFunctionApi, postFunctionApi } from '../../utils';
import './SendRequestPage.css';
import { getToken } from '../../auth';

export default function CreateRequestPage(props: {instance: any}) {
	const { accounts } = useMsal();
	const account = useAccount(accounts[0] || {});

	const navigate = useNavigate();

	const [ data, setData ] = useState({
		userName: account.name,
		userEmail: account.username,
    roleIds: []
	} as AccessRequestForm);
  const [ tenantList, setTenantList ] = useState([] as IdNameDto[]);
  const [ roleList, setRoleList ] = useState([] as RoleDto[]);
  const [ token, setToken ] = useState('');
  const [ disabled, setDisabled ] = useState(true);

  useEffect(() => {
    getToken(props.instance, account)
      .then(result => setToken(result))
      .catch(error => alert(error));
  }, [props, account]);

  useEffect(() => {
    if (!token) return;

    loadRoles(token)
      .then(result => {
        setRoleList(result)
      })
      .catch(error => {
        alert(error);
      });

		loadTenants(token)
      .then(result => {
        setTenantList(result)
      })
      .catch(error => {
        alert(error);
      });
    }, [token]);

  const loadRoles = async(token: string) =>
		await fetchFunctionApi<RoleDto[]>('/roles/all', {
      headers: {
      'Authorization': `Bearer ${token}`
    }
  });
	
  const loadTenants = async(token: string) =>
		await fetchFunctionApi<IdNameDto[]>('/tenants/all', {
      headers: {
      'Authorization': `Bearer ${token}`
    }
  });
	
	const onSubmit = async() => {
		await postFunctionApi('/requests', JSON.stringify(data), token);
		navigate(`../success`);
	}

	const onReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		data.reason = event.target.value;
    updateData(data);
  }

	const onTenantChange = (value: string) => {
		data.tenantId = value;
		data.domain = tenantList.find(x => x.id === value).name;
    updateData(data);
	}
	
	const onRoleChange = (value: string[]) => {
		data.roleIds = value;
    updateData(data);
  }
	
	const onDatesChange = (value: Dayjs) => {
		if (value) {
			data.endDate = value.format("YYYY-MM-DD");
    } else {
      data.endDate = null;
    }
    updateData(data);
	}

  const updateData = (data: AccessRequestForm) => {
    setData(data);
    setDisabled(!(data.tenantId && data.domain && data.endDate && data.reason && data.roleIds.length > 0));
  }

	return (
		<div className='formbox' style={{
			display: 'inline-grid',
			width: '400px',
			textAlign: 'left'
		}}>
			<div className='inputRow'>
				<b>New access request</b>
			</div>
			<div className='inputRow'>
        <p style={{marginRight: "10px"}}>Tenant</p>
				<SelectTenant onChange={onTenantChange} tenants={tenantList}/>
			</div>
			<div className='inputRow'>
				<p style={{marginRight: "10px"}}>Role</p>
				<SelectRole onChange={onRoleChange} roles={roleList}/>
			</div>
			<div className='inputRow'>
				<p style={{marginRight: "10px"}}>Required until</p>
				<CustomDatePicker
					onChange={onDatesChange}
				/>
			</div>
			<div className='inputRow'>
				<p>Reason</p>
			</div>
      <TextArea rows={4} onChange={onReasonChange} />
			
			<div style={{display: 'inline-box', alignItems: 'center'}}>
				<Button type="primary" icon={<SendOutlined />} onClick={onSubmit} disabled={disabled}>
					Send
				</Button>
			</div>
		</div>
	)
}