import { Select, SelectProps } from 'antd';

type OptionType = { label: string, value: string };

export function SearchSelect(props: SelectProps<string, OptionType>) {
  return <Select
    showSearch
    style={{ width: 350 }}
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
    {...props}
  />
}