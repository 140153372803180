import { HashRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import { MsalProvider } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import './styles/App.css';
import SendRequestPage from './pages/SendRequest';
import SuccessPage from './pages/SuccessPage';

function App({instance}) {
  return (
    <MsalProvider instance={instance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Router>
          <div className="App">
            <div className="App-body">
              <header className="title">
                <h1>IT Partner. Access Management</h1>
              </header>
              <Routes>
                <Route path="/" element={<Navigate replace to="/new-request" />} />
                <Route path="/new-request" element={<SendRequestPage instance={instance}/>}/>
                <Route path="/success" element={<SuccessPage/>}/>
              </Routes>
            </div>
          </div>
        </Router>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
