import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from "dayjs";

export function CustomDatePicker(props: DatePickerProps) {
  const disabledDate = (current: Dayjs) => {
		// Can not select days before today
		return current && current < dayjs().startOf('day');
	};

  return <DatePicker disabledDate={disabledDate} {...props}/>
}